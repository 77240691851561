export type DocumentStatus = 'I' | 'V' | 'U' | 'C' | 'F' | 'N' | 'S';
export const DocumentStatusNew: DocumentStatus = 'N';
export const DocumentStatusSaved: DocumentStatus = 'S';
export const DocumentStatusInserted: DocumentStatus = 'I';
export const DocumentStatusUpdated: DocumentStatus = 'U';
export const DocumentStatusConfirmed: DocumentStatus = 'C';
export const DocumentStatusFinalized: DocumentStatus = 'F';
export const DocumentStatusUpdatedPlus: DocumentStatus = 'V';

export enum PasswordStrength {
  UppercaseLetter,
  LowercaseLetter,
  Number,
  SpecialCharacter,
}

export const DocumentStatusString = {
  N: 'Nova rezervacija',
  S: 'Shranjen',
  I: 'Vnesen',

  U: 'Shranjen',
  C: 'Potrjen',
  F: 'Zaključen',
  V: 'Oddan',
};

export enum TabIndex {
  Notifications = 0,
  Reservation = 1,
  Document = 2,
}

export enum Navigation {
  Analytics = 0,
  User = 1,
  Structure = 2,
  Point = 3,
  Vehicle = 4,
  Subsistence = 5,
  Settings = 6,
  Dashboard = 7,
  AnalyticsUser = 8,
  AboutMe = 100,
}

export const NavigationText: string[] = [
  'Analitika',
  'Uporabniki',
  'Struktura',
  'Točke',
  'Vozila',
  'Dnevnice',
  'Nastavitve',
  'Potni nalogi',
  'Seznam potnih nalogov'
];

export enum NDN {
  BasicInformation,
  AccountingInformation,
  Attachments,
}

export enum NRN {
  BasicInformation,
  Notification,
  PathInformation,
}

export enum VehicleType {
  None,
  Company,
  Private,
  Companion,
}

export enum PointType {
  None,
  Public,
  Private,
}

export const vehicleType: string[] = ['N', 'S', 'Z', 's'];
export const vehicleDesc: string[] = [
  '-',
  'Službeno vozilo',
  'Zasebno vozilo',
  'Sopotnik',
];

export enum Action {
  Start,
  Left,
  Right,
  End,
}

export enum ChangeUserData {
  Email,
  Firstname,
  Lastname,
  Password,
  Company,
  Telephone,
  // Point
}

export enum UserType {
  Super,
  Admin,
  Regular,
  None = 100,
}
