import React from 'react';
import { hot } from 'react-hot-loader';
import Main from './Main';
import Login from './Login';
import { UserData, userdata } from './interfaces';
import { decrypt } from './routes';
import Cookies from 'js-cookie';
import axios from 'axios';
import { UserType } from './enum';

interface State {
  login: boolean
  register: boolean
  userData: UserData
  loading: boolean
  proceed: boolean
}

class App extends React.Component<unknown, State> {
  constructor(props) {
    super(props);
    const path: string = Cookies.get('path');

    let login = true;
    let register = false;
    if (path && path.length) {
      if (path === 'main') {
        login = false;
      } else if (path === 'register') {
        register = true;
      }
    }

    const token: string = Cookies.get('token');
    if (token === undefined) {
        login = true;
    }

    this.state = {
      proceed: false,
      loading: false,
      userData: {
        token,
        companyuuid: '',
        firstname: '',
        telephone: '',
        lastname: '',
        email: '',
        uuid: '',
        usertype: undefined,
      },
      login,
      register,
    };
    this.router = this.router.bind(this);
    this.changeUserData = this.changeUserData.bind(this);
    this.changeUserDataAdmin = this.changeUserDataAdmin.bind(this);
  }

  router(register: boolean, login: boolean, userData?: UserData) {
    userData
      ? this.setState({ register, login, userData, proceed: true })
      : this.setState({ register, login });
  }

  changeUserData(variable: userdata, value: string) {
    const userData: UserData = Object.assign({}, this.state.userData);
    userData[variable] = value;
    this.setState({ userData, proceed: true });
  }

  changeUserDataAdmin(value: number) {
    const userData: UserData = Object.assign({}, this.state.userData);
    userData.usertype = value;
    this.setState({ userData, proceed: true });
  }

  load() {
    axios.post(decrypt, { token: this.state.userData.token }).then(resp => {
      if (resp.data.resolved) {
        const companyuuid: string = resp.data.data.companyuuid;
        const firstname: string = resp.data.data.firstname;
        const telephone: string = resp.data.data.telephone;
        const lastname: string = resp.data.data.lastname;
        const email: string = resp.data.data.email;
        const uuid: string = resp.data.data.uuid;
        const usertype: number = resp.data.data.usertype;
        const userData = Object.assign({}, this.state.userData);
        userData.companyuuid = companyuuid ? companyuuid : '';
        userData.firstname = firstname ? firstname : '';
        userData.telephone = telephone ? telephone : '';
        userData.lastname = lastname ? lastname : '';
        userData.email = email ? email : '';
        userData.uuid = uuid ? uuid : '';
        userData.usertype = usertype !== undefined && usertype !== null ? usertype : UserType.None;
        this.setState({ userData, proceed: true });
      }
    });
  }

  render() {
    if (
      this.state.userData.email === '' &&
      this.state.userData.token &&
      this.state.loading === false
    ) {
      this.setState({ loading: true });
      this.load();
    }
    return (
      <div className="wrapper">
        {this.state ? (
          this.state.login ? (
            <Login
              userData={this.state.userData}
              changeUserData={this.changeUserData}
              router={this.router}
            />
          ) : this.state.proceed ? (
            <Main
              userData={this.state.userData}
              changeUserData={this.changeUserData}
              changeUserDataAdmin={this.changeUserDataAdmin}
              router={this.router}
            />
          ) : (
            <div />
          )
          ) : (
            <div />
          )
        }
      </div>
    );
  }
}

declare const module: object;
export default hot(module)(App);
