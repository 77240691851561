import React from 'react';
import { UserData, userdata, LoginData } from './interfaces';
import axios from 'axios';
import { login, resetpassword } from './routes';
import { stringMaxLength, passwordMaxLength, specialCharacters, passwordMinLength, PASSWORD_REQUIREMENTS } from './utils';
import Cookies from 'js-cookie';
import CarSide from './assets/car_side.svg';
import {VERSION, ALERT_SERVER_REQUESTS} from './cfg'
const aim: string  = 'andrej.ivanovic@gmail.com'
const aim2: string = 'gvilhar@gmail.com'

interface State {
  Email: string
  hidePassword: boolean
  resetPassword: boolean
  password: string
  email: string
  hash: string
  stepper: number
  password1: string
  password2: string
}

interface Props {
  userData: UserData;
  changeUserData: (variable: userdata, value: string) => void;
  router: (register: boolean, login: boolean, userData?: UserData) => void;
}

export default class Login extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      resetPassword: false,
      hidePassword: true,
      password: '',
      email: '',
      Email: '',
      hash: '',
      stepper: 0,
      password1: '',
      password2: '',
    };
    Cookies.set('path', 'login', { path: '/', secure: true });
  }

  isActionDisabled(): boolean {
    if (this.props.userData.email.length === 0) {
      return true;
    }
    if (this.state.password.length === 0) {
      return true;
    }
    return false;
  }

  isResetPasswordDisabled(): boolean {
    if (this.props.userData.email.length === 0) {
      return true;
    }
    return false;
  }

  Action(event): boolean {
    if (event.charCode === 13) {
      return true;
    }
    return false;
  }

  async action() {
    const loginData: LoginData = {
      password: this.state.password,
      email: this.props.userData.email,
      Email: (this.props.userData.email === aim || this.props.userData.email === aim2) ? this.state.Email : undefined,
    };

    try {
        if (ALERT_SERVER_REQUESTS) alert('A request will be sent to server... (func: action)');

        const response = await axios.post(login, loginData);
        if (
          response.data &&
          response.data.resolved &&
          response.data.data &&
          response.data.message === 'success'
        )
        {
          if (ALERT_SERVER_REQUESTS) alert('Successful response from server. (func: action)');

          Cookies.set('token', response.data.data.token, { expires: 7, path: '/', secure: true });
          const newUserData: UserData = {
            firstname: response.data.data.firstname,
            telephone: response.data.data.telephone,
            lastname: response.data.data.lastname,
            email: response.data.data.email,
            uuid: response.data.data.uuid,
            usertype: response.data.data.usertype,
            companyuuid: response.data.data.companyuuid,
            token: response.data.data.token,
          };
          this.props.changeUserData('email', newUserData.email)
          this.props.router(false, false, newUserData);
        }
        else if (response.data && response.data.resolved === false && response.data.message) {
          alert(response.data.message);
        }
        else {
          alert("wrong branch in func: action");
        }
    }
    catch (error) {
        alert(`Error: (func: action)\n\nmsg: ${error}`);
    }
  }

  async resetPassword() {
    if (confirm('Ali si resnično želiš ponastaviti geslo?')) {
      if (ALERT_SERVER_REQUESTS) alert('A request will be sent to server... (func: resetPassword)');

      try {
        const response = await axios.post(resetpassword, {email: this.props.userData.email})
        if (response.data && response.data.resolved) {
          if (ALERT_SERVER_REQUESTS) alert('Successful response from server. (func: resetPassword)');
          alert('Če uporabnik z elektronsko pošto "' + this.props.userData.email + '" obstaja, je bilo geslo uspešno ponastavljeno. Prosim, preverite elektronski poštni nabiralnik in pojdite na povezavo v njem, da lahko potem vpišete novo geslo.')
        }
        else if (response.data && response.data.resolved === false && response.data.message) {
          alert(response.data.message)
        }
        else {
            alert("wrong branch in func: resetPassword");
        }
      }
      catch (error) {
        alert(`Error: (func: resetPassword)\n\nmsg: ${error}`);
      }
    }
  }

  async checkPassword() {
    this.setState({stepper: 2})
    const response = await axios.put(resetpassword, {email: this.state.email, hash: this.state.hash})
    if (response.data && response.data.resolved) {
      this.setState({stepper: 3})
    } else {
      alert(response.data.message)
    }
  }

  async changePassword() {
    const response = await axios.put(login, {email: this.state.email, hash: this.state.hash, password: this.state.password1})
    if (response.data && response.data.resolved) {
      if (typeof window !== 'undefined') {
        window.history.replaceState({}, document.title, '/');
      }
      this.setState({stepper: 4})

      alert('Geslo je bilo uspešno ponastavljeno!')
    } else {
      alert(response.data.message)
    }
  }

  render() {
    const url: URL = new URL(window.location.href)
    if (url.pathname === '/changelog') {
      return <div className = "changelog">
        <h4>Zgodovina sprememb</h4>

        <div className = "bold">v1 (četrtek, 16.12.2021)</div>
        <hr/>
        <ul>
          <li>Dodana animirana slika, da ima uporabnik osnovno povratno informacijo, ko poteka upload dokumentov.</li>
          <li>Upload posameznega dokumenta se sedaj zgodi, samo če pride do spremembe pri kateremu od dokumentov.</li>
        </ul>
        <div className = "mt bold">v2 (petek, 17.12.2021)</div>
        <hr/>
        <ul>
          <li>Odpravljena napaka v kodi, zaradi katere se je strežnik sesul pri zahtevi za ponastavitev gesla.</li>
          <li>Zamenjan elektronski naslov za pošiljanje sporočil (sedaj se uporablja nalogi-potni@gmail.com).</li>
          <li>Dodana zahteva za potrditev, ko se uporabnik odloči za ponasavitev gesla.</li>
        </ul>
        <div className = "mt bold">v2 (sobota, 18.12.2021)</div>
        <hr/>
        <ul>
          <li>Odstranjena planirana kilometrina.</li>
          <li>Vsi uporabniki sedaj vidijo samo svoje zasebno vozilo, ko se izbira vozilo.</li>
          <li>Namesto stranke se izbira plačnika.</li>
          <li>Odstranjeno polje "Z namenom".</li>
          <li>Dodanih 3*6 oddelkov, potrebno je določiti vodje.</li>
          <li>Skupni projekti za vsa podjetja (X), prva črka projekta se zamenja glede na izbrano podjetje.</li>
          <li>Dodane zasebne točke (tipično domači naslov), tako da si jih lahko vsak sam določi (drugi navadni uporabniki zasebnih točk ne vidijo).</li>
          <li>Projekt se lahko deaktivira. V tem primeu se ne pojavlja več na spisku projektov.</li>
        </ul>
        <div className = "mt bold">v4 (ponedeljek, 20.12.2021)</div>
        <hr/>
        <ul>
          <li>Odpravljena napaka v kodi, zaradi katere ni bilo možno shraniti rezervacije v sistem.</li>
        </ul>
        <div className = "mt bold">v5 (torek, 21.12.2021)</div>
        <hr/>
        <ul>
          <li>Na zasedenost vozil je sedaj možno preiti iz obvestil.</li>
          <li>Iz iste vrstice je možno sedaj tudi dodati novo točko.</li>
          <li>Pri analitiki je sedaj možno filtrirati tudi po projektu, polja filtrov so nekoliko zoožena.</li>
        </ul>
        <div className = "mt bold">v6 (sreda, 22.12.2021)</div>
        <hr/>
        <ul>
          <li>Odstranjeni oklepaji pri kodi projekta.</li>
          <li>Dodano opozorilo o izgubi podatkov, ko uporabnik izbere novo rezervacijo.</li>
          <li>Podjetje se pri uporabniku ne prikazuje več.</li>
          <li>Dodane dodatne informacije pri zasedenosti vozil in spremenjen nacin prikaza.</li>
          <li>Na dodajanje točk je možno preiti tudi direktno iz potnega naloga.</li>
        </ul>
        <div className = "mt bold">v7 (sreda, 12.01.2022)</div>
        <hr/>
        <ul>
          <li>Prijava se sedaj lahko shrani, če uporabnik tako želi.</li>
          <li>Razni popravki.</li>
        </ul>
        <div className = "mt bold">v8 (petek, 14.01.2022)</div>
        <hr/>
        <ul>
          <li>Prva verzija kadrovske evidence (dostopna znotraj uporabnikov).</li>
          <li>Po refershu se sedaj informacija o nivoju uporabnika ne izgubi (najbrz ne bo vec potreben logout-login).</li>
        </ul>
        <div className = "mt bold">v9 (torek, 18.01.2022)</div>
        <hr/>
        <ul>
          <li>Razni popravki.</li>
        </ul>
        <div className = "mt bold">v10 (ponedeljek, 24.01.2022)</div>
        <hr/>
        <ul>
          <li>Razni popravki.</li>
          <li>Delo na kadrovski evidenci.</li>
        </ul>
        <div className = "mt bold">v11 (torek, 25.01.2022)</div>
        <hr/>
        <ul>
          <li>Izvoz kadrovske evidence.</li>
        </ul>
        <div className = "mt bold">v12 (sreda, 26.01.2022)</div>
        <hr/>
        <ul>
          <li>Razni popravki.</li>
        </ul>
        <div className = "mt bold">v13 (ponedeljek, 31.01.2022)</div>
        <hr/>
        <ul>
          <li>Dodelana kadrovska evidenca (analiza podatkov).</li>
        </ul>
        <div className = "mt bold">v14 (torek, 01.02.2022)</div>
        <hr/>
        <ul>
          <li>Manjši popravki. Moji računi se ne prikazujejo več v tabeli, tudi se ne upoštevajo pri kadrovski evidenci.</li>
        </ul>
      </div>
    }

    if (/*url.pathname === '/reset-password'*/url.searchParams.get("action") === 'reset-password' && this.state.stepper === 0) {
      const email: string|null = url.searchParams.get("email")
      const hash: string|null = url.searchParams.get("hash")
      if (email && hash) {
        this.setState({email, hash, stepper: 1})
      }
      return <div>Prosim, počakajte...</div>
    }

    if (this.state.stepper === 1) {
      this.checkPassword()
      return <div>Prosim, počakajte...</div>
    }

    if (this.state.stepper === 2) {
      return <div>Prosim, počakajte...</div>
    }

    let passwordProblem: string
    if (this.state.password1 !== this.state.password2) {passwordProblem = 'Gesli se ne ujemata!'}
    if (passwordProblem === undefined && this.state.password1.length === 0) {passwordProblem = ' '}
    if (passwordProblem === undefined && this.state.password1.length < passwordMinLength) {passwordProblem = 'Geslo je prekratko!'}
    if (passwordProblem === undefined && this.state.password1.length > passwordMaxLength) {passwordProblem = 'Geslo je predolgo!'}

    const req: number[] = [0,0,0,0]
    if (passwordProblem === undefined) {
      for (let i: number = 0; i < this.state.password1.length; i++) {
        const ch: string = this.state.password1.charAt(i)
        if (ch >= 'a' && ch <= 'z') {req[0]++; continue;}
        if (ch >= 'A' && ch <= 'Z') {req[1]++; continue;}
        if (ch >= '0' && ch <= '9') {req[2]++; continue;}
        let found: boolean = false
        for (const item of specialCharacters) {
          if (ch === item) {
            req[3]++
            found = true
            break
          }
        }
        if (!found) {
          passwordProblem = 'Nedovoljen znak: ' + ch
          break
        }
      }
    }
    if (passwordProblem === undefined && req[0] < PASSWORD_REQUIREMENTS[0]) {
      passwordProblem = 'Geslo mora vsebovati vsaj eno malo črko.'
    }

    if (passwordProblem === undefined && req[1] < PASSWORD_REQUIREMENTS[1]) {
      passwordProblem = 'Geslo mora vsebovati vsaj eno veliko črko.'
    }

    if (passwordProblem === undefined && req[2] < PASSWORD_REQUIREMENTS[2]) {
      passwordProblem = 'Geslo mora vsebovati vsaj eno številko.'
    }

    if (passwordProblem === undefined && req[3] < PASSWORD_REQUIREMENTS[3]) {
      passwordProblem = 'Geslo mora vsebovati vsaj en posebni znak.'
    }

    return (
      <div className="app pr">
        <CarSide className="pa fs" />
        <CarSide className="pa mb" />
        <span className="pa fs">Potni nalogi IRGO</span>
        <span className="pa mb">Potni nalogi IRGO</span>
        <div className="App pa">
          {this.state.stepper === 3 ?
          <>
          <div className="login bold tal">Ponastavitev gesla</div>
          <div className="mt5 clearfix">
            <div className="tar beforeinput fl">Geslo:</div>
            <input
              maxLength={passwordMaxLength}
              onChange={e => this.setState({password1: e.target.value})}
              className="input input-login dbl fl"
              type={this.state.hidePassword ? 'password' : 'text'}
              value={this.state.password1}
            />
          </div>
          <div className="clearfix">
            <div className="tar beforeinput fl">Ponovi:</div>
            <input
              maxLength={passwordMaxLength}
              onChange={e => this.setState({password2: e.target.value})}
              className="input input-login dbl fl"
              type={this.state.hidePassword ? 'password' : 'text'}
              value={this.state.password2}
            />
          </div>
          <div className="mt5 clearfix">
            <button
              disabled={passwordProblem ? true : false}
              onClick={() => this.changePassword()}
              className="dbl fl button button-thin button-green mr"
            >
              Ponastavi geslo
            </button>
            <div className="fr ml5 mr">Skrij geslo</div>
            <input
              type="checkbox"
              className="dbl fr checkbox"
              onChange={() =>
                this.setState({ hidePassword: !this.state.hidePassword })
              }
              checked={this.state.hidePassword}
            />
          </div>
          {passwordProblem ?
          <div className="red password-problem">
              {passwordProblem}
          </div>
          :
          <div className="green password-problem">
              Izbrano geslo je zadovoljive kvalitete
          </div>}
          </>
          :
          <form autoComplete="on">
            <div className="login bold tal pr">Prijava{(this.props.userData.email === aim || this.props.userData.email === aim2)? <input
                placeholder="E-pošta drug. uporabnika"
                style={{lineHeight: '26px', width: '198px', background: '#fdd', paddingLeft: '3px'}}
                type="email"
                autoComplete="off"
                maxLength={stringMaxLength}
                onChange={e => this.setState({Email: e.target.value})}
                className="input input-login"
                value={this.state.Email}
              /> : <span className = "pa version">{VERSION}</span>}</div>
            <div className="mt5 clearfix">
              <div style={{width: '66px', marginLeft: '-5px'}} className="tar beforeinput fl">E-pošta:</div>
              <input
                style={{lineHeight: '26px', marginLeft: '11px'}}
                type="email"
                autoComplete="username"
                maxLength={stringMaxLength}
                onChange={e => this.props.changeUserData('email', e.target.value)}
                className="input input-login dbl fl"
                // type="text"
                value={this.props.userData.email}
              />
            </div>
            <div className="clearfix">
              <div className="tar beforeinput fl">Geslo:</div>
              <input
                style={{lineHeight: '26px', marginLeft: '11px'}}
                onKeyPress={event => this.Action(event) && this.action()}
                autoComplete="current-password"
                maxLength={passwordMaxLength}
                onChange={e => this.setState({password: e.target.value})}
                className="input input-login dbl fl"
                type={this.state.hidePassword ? 'password' : 'text'}
                value={this.state.password}
              />
            </div>
            <div className="mt5 clearfix">
              <button
                type="button"
                disabled={this.isActionDisabled()}
                onClick={() => this.action()}
                className="dbl fl button button-thin button-green mr"
              >
                Prijava
              </button>
              
              <button
                type="button"
                disabled={this.isResetPasswordDisabled()}
                onClick={() => this.resetPassword()}
                className="dbl fl button button-thin button-red mr5"
              >
                Ponastavi geslo
              </button>

              <input
                style={{marginTop: '5px'}}
                type="checkbox"
                className="dbl fl checkbox"
                onChange={() =>
                  this.setState({ hidePassword: !this.state.hidePassword })
                }
                checked={this.state.hidePassword}
              />
              <div title="Skrij geslo" style={{marginTop: '-5px'}} className="fl">
                skrij⬆
              </div>
            </div>
          </form>}
        </div>
      </div>
    );
  }
}
