import {CodeRegister} from './interfaces';

export const education: CodeRegister[] = [
    {id: 1, value: 'osnovna šola'},
    {id: 2, value: 'srednja šola'},
    {id: 3, value: 'gimnazijski maturant'},
    {id: 4, value: 'dipl. inž. grad.'},
    {id: 5, value: 'dipl. inž. rud. in geotehnol.'},
    {id: 6, value: 'univ. dipl. inž. geol.'},
    {id: 7, value: 'univ. dipl. inž. grad.'},
    {id: 8, value: 'univ. dipl. inž. rud. in geotehnol.'},
    {id: 9, value: 'mag. inž. geol.'},
    {id: 10, value: 'mag. inž. geotehnol.'},
    {id: 11, value: 'visoka strok. izobrazba druge smeri'},
    {id: 12, value: 'univ. strok. izobrazba druge smeri'},
    {id: 13, value: 'mag. znanosti, univ. dipl. inž. geol.'},
    {id: 14, value: 'mag. znanosti, univ. dipl. inž. rud. in geotehnol.'},
    {id: 15, value: 'dr. znanosti, univ. dipl. inž. geol.'},
    {id: 16, value: 'dr. znanosti, univ. dipl. inž. grad.'},
    {id: 17, value: 'mag. inž. grad.'},
    {id: 18, value: 'mag. znanosti, univ. dipl. inž. grad.'},
    {id: 19, value: 'mag. strokovna izobrazba druge smeri'},
  ]
  
  export const workPosition: CodeRegister[] = [
    {id: 1, value: 'administrator'},
    {id: 2, value: 'direktor'},
    {id: 3, value: 'namestnik vodje oddelka, vodja projektov'},
    {id: 4, value: 'pomočnik vrtalca'},
    {id: 5, value: 'referent knjiženja in kadrovskih zadev'},
    {id: 6, value: 'samostojni referent'},
    {id: 7, value: 'strokovni sodelavec'},
    {id: 8, value: 'tehnični sodelavec'},
    {id: 9, value: 'vodja oddelka, vodja projektov'},
    {id: 10, value: 'vodja projektov'},
    {id: 11, value: 'vrtalec'},
    {id: 12, value: 'vzdrževalec/laborant'},
    {id: 13, value: 'strokovni sodelavec - projektant'},
    {id: 14, value: 'strokovni sodelavec - laborant'},
  ]
  
  export const specialPermits: CodeRegister[] = [
    {id: 1, value: 'pooblaščeni inženir IZS'},
    {id: 2, value: 'odgovorni rudarski projektant'},
    {id: 3, value: 'vodja del'},
  ]
  
  export const sections: CodeRegister[] = [
    {id: 1, value: 'Splošno'},
    {id: 2, value: 'Geologija'},
    {id: 3, value: 'Monitoring'},
    {id: 4, value: 'Gradbeništvo'},
    {id: 5, value: 'Hidrogeologija'},
    {id: 6, value: 'Geotehnika'},
  ]
  
  export const working: CodeRegister[] = [
    {id: 1, value: 'določen čas'},
    {id: 2, value: 'nedoločen čas'},
    {id: 3, value: 'podjemna pogodba'},
    {id: 4, value: 'avtorska pogodba'},
    {id: 5, value: 'študent'},
    {id: 6, value: 'dopolnilno delo'},
  ]
  
  export const sex: CodeRegister[] = [
    {id: 1, value: 'moški'},
    {id: 2, value: 'ženski'},
  ]
  
  export const usertype: CodeRegister[] = [
    {id: 0, value: 'super'},
    {id: 1, value: 'admin'},
    {id: 2, value: 'navaden'},
  ]
  
  export const workingStatus: CodeRegister[] = [
    {id: 1, value: 'aktiven'},
    {id: 2, value: 'neaktiven'},
  ]
  
  export const yesno: CodeRegister[] = [
    {id: 1, value: 'da'},
    {id: 2, value: 'ne'},
  ]

  export const title: CodeRegister[] = [
    {id: 1, value: 'dr.'},
    {id: 2, value: 'mag.'},
  ]

  export const codeRegister: Array<{what: string, value: CodeRegister[]}> = [
    {what: 'Izobrazba', value: education},
    {what: 'Vrsta zaposlitve', value: working},
    {what: 'Delovno mesto', value: workPosition},
    {what: 'Posebna pooblastila', value: specialPermits},
    {what: 'Oddelek', value: sections},
    {what: 'Spol', value: sex},
    {what: 'Uporabniški nivo', value: usertype},
    {what: 'Status zaposlitve', value: workingStatus},
    {what: 'Izpit iz varstva pri delu', value: yesno},
    {what: 'Zdravniški pregled', value: yesno},
    {what: 'Naziv', value: title},
  ]