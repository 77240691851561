import {DEV} from './cfg'
// export const backendServer: string = (DEV ? 'http://127.0.0.1:9090/' : 'https://zugzwang.xyz/') + 'server/'
export const backendServer: string = (DEV ? 'http://127.0.0.1:9090/' : 'https://potni-nalogi.irgo.si/') + 'server/'
export const init: string = backendServer + 'init';
export const login: string = backendServer + 'login';
export const register: string = backendServer + 'register';
export const user: string = backendServer + 'user';
export const useractive: string = backendServer + 'user-active';
export const decrypt: string = backendServer + 'decrypt';
export const company: string = backendServer + 'company';
export const point: string = backendServer + 'point';
export const general: string = backendServer + 'general';
export const project: string = backendServer + 'project';
export const vehicle: string = backendServer + 'vehicle';
export const subsistence: string = backendServer + 'subsistence';
export const mileage: string = backendServer + 'mileage';
export const googlepath: string = backendServer + 'googlepath';
export const document: string = backendServer + 'document';
export const section: string = backendServer + 'section';
export const expense: string = backendServer + 'expense';
export const reservation: string = backendServer + 'reservation';
export const status: string = backendServer + 'status';
export const country: string = backendServer + 'country';
export const analytics: string = backendServer + 'analytics';
export const resetpassword: string = backendServer + 'reset-password';
export const settings: string = backendServer + 'settings';
export const sequential: string = backendServer + 'sequential';
export const pointexposed: string = backendServer + 'pointexposed';
// export const customer: string = backendServer + 'customer';