export const c1s = 'GRI'
export const c2s = 'IRGO'
export const c3s = 'IC'

// export const c1s = 'Pod1'
// export const c2s = 'Pod2'
// export const c3s = 'Pod3'

const companies = `(${c1s}/${c2s}/${c3s})`
const startwork = `Začetek nastopa dela ${companies}`
const hoursWeekCompany = 'Št. ur na teden ('
export const startWork: unique symbol = Symbol(startwork)
export const startWork_: unique symbol = Symbol(`${startwork}_`)
export const workHere: unique symbol = Symbol(`Delovna doba - ${companies}`)
export const hoursWeekCompany1: unique symbol = Symbol(`${hoursWeekCompany}${c1s})`)
export const hoursWeekCompany2: unique symbol = Symbol(`${hoursWeekCompany}${c2s})`)
export const hoursWeekCompany3: unique symbol = Symbol(`${hoursWeekCompany}${c3s})`)